import * as React from "react";
import Layout from "../../components/Layout";

const AboutUsPage = () => {
  return (
    <Layout pageTitle="About Us">
      <h1 className="text-2xl font-bold">About Us</h1>
    </Layout>
  );
};

export default AboutUsPage;
